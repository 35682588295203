import { useState, useEffect } from "react";
import { usePreference } from "store/usePreference";
import configs from "tailwind.config.js";

interface Themes {
  main: Theme;
  green: Theme;
}

interface Theme {
  primary: string;
  secondary: string;
  accent: string;
  neutral: string;
  success: string;
  warning: string;
  error: string;
}
let { daisyui } = configs;
const themes: Themes = daisyui.themes[0];

export const useTheme = () => {
  const [themeColors, setThemeColors] = useState<Theme>(themes.main);
  const { appTheme }: any = usePreference();

  // useEffect(() => {
  //   if (appTheme) setThemeColors(themes[appTheme as keyof Themes]);
  // }, [appTheme]);

  return { themeColors };
};
