import { useState, useEffect } from "react";
import { BsApple } from "react-icons/bs";
import { FcAndroidOs } from "react-icons/fc";
import appStore from "public/assets/app-store.png";
import playStore from "public/assets/play-store.png";
import Image from "next/image";
import { AiFillCloseCircle } from "react-icons/ai";
import Link from "next/link";

function MobileAppAdBanner() {
  const [isMobile, setIsMobile] = useState({
    status: false,
    userAgent: "",
  });
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    if (/andriod/i.test(navigator.userAgent.toLowerCase())) {
      setIsMobile({
        status: true,
        userAgent: "android",
      });
    }
    if (/iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase())) {
      setIsMobile({
        status: true,
        userAgent: "iphone",
      });
    }
  }, []);
  return (
    <>
      {isMobile.status && showBanner && (
        <div className="fixed bottom-0 left-0 z-50 w-full p-4 bg-white rounded-lg shadow-lg shadow-gray-400">
          <div className="flex flex-col justify-between md:flex-row md:items-center">
            <div className="mb-4 text-center md:text-left md:mb-0">
              <h1 className="text-lg ">Download the app</h1>
              <p className="text-gray-600">
                Get the best deals and offers on your phone
              </p>
            </div>
            <div className="flex justify-center md:justify-end">
              <Link
                href={
                  isMobile.userAgent === "android"
                    ? "https://play.google.com/store/apps/details?id=com.zeidex.macqueen"
                    : "https://apps.apple.com/eg/app/macqueen/id1495088277"
                }
                target="_blank"
              >
                <Image
                  src={isMobile.userAgent === "android" ? playStore : appStore}
                  alt="app store"
                  width={180}
                  height={50}
                />
              </Link>
            </div>
          </div>
          <div
            className="absolute top-0 left-0 p-2 cursor-pointer"
            onClick={() => setShowBanner(false)}
          >
            <AiFillCloseCircle size={20} />
          </div>
        </div>
      )}
    </>
  );
}

export default MobileAppAdBanner;
