import { useEffect } from "react";
import { useUserStore } from "store/useUserStore";

export enum SingularEventType {
  SEARCH = "sng_search",
  BOOKED = "sng_ecommerce_purchase",
  CONTENT_VIEW = "sng_content_view",
  REACHED_BOOKING_STEP = "sng_reached_booking_step",
  PAYMENT_PAGE = "sng_payment_page",
}
const PRODUCT_ID = "co.macqueen";

export const setSingularEventTracker = (eventName: string, params?: any) => {
  import("singular-sdk" as any).then((res) => {
    const { singularSdk } = res;
    singularSdk.event(eventName, params);
  });
};

export const useSingularTracker = () => {
  const { user }: any = useUserStore();

  const initSingularTracker = () => {
    // It had to imported here in client side to avoid SSR error `window` is not defined
    import("singular-sdk" as any).then((res) => {
      const { SingularConfig, singularSdk } = res;
      const config = new SingularConfig(
        process.env.NEXT_PUBLIC_SINGULAR_SDK_KEY as string,
        process.env.NEXT_PUBLIC_SINGULAR_SDK_SECRET as string,
        PRODUCT_ID
      );
      if (user) config.withCustomUserId(user.email || user.mobile);

      singularSdk.init(config);
    });
  };

  const trackLoggedUser = () => {
    import("singular-sdk" as any).then((res) => {
      const { singularSdk } = res;

      if (user) singularSdk.login(user.email || user.mobile);
      else singularSdk.logout();
    });
  };

  useEffect(() => {
    initSingularTracker();
  }, []);

  useEffect(() => {
    trackLoggedUser();
  }, [user]);
};
