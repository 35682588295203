'use client'
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { useEffect } from "react";
import { useUserStore } from "store/useUserStore";

const generateVisitorId = () => {
  const { setVisitorId }: any = useUserStore();

  const { getData: getFPData } = useVisitorData(
    { extendedResult: true },
    { immediate: true }
  );

  const fetchVisitorId = async () => {
    const data = await getFPData();
    setVisitorId(data.visitorId);
    window.localStorage.setItem("visitorId", data.visitorId);
   };

  useEffect(() => {
    fetchVisitorId();
  }, []);

  return false;
};
export default generateVisitorId;
