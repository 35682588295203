import log from "loglevel";

log.setLevel((process.env.NEXT_PUBLIC_LOG_LEVEL as log.LogLevelDesc) || "info");

export const logError = (message: string) => {
  log.error(`[ERROR]`, message);
};

export const logDebug = (message: string) => {
  log.debug(`[DEBUG]`, message);
};

export const logInfo = (message: string) => {
  log.info(`[INFO]`, message);
};
