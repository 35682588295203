import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { useUserStore } from "store/useUserStore";
import { logError } from "utils/helpers/loggers";

const ENV = process.env.NEXT_PUBLIC_ENV;

export const useMixPanelTracker = () => {
  const user = useUserStore().user;

  useEffect(() => {
    if (ENV === "PROD") {
      const options = {
        method: "POST",
        headers: { accept: "text/plain", "content-type": "application/json" },
        body: JSON.stringify([
          {
            $token: process.env.NEXT_PUBLIC_MIXPANEL_API_KEY,
            $distinct_id: "13793",
            $set: { newKey: "New Value" },
          },
        ]),
      };

      fetch("https://api.mixpanel.com/engage#profile-set", options)
        .then((response) => response.json())
        .catch((err) => logError(err));

      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_API_KEY as string, {
        debug: true,
        track_pageview: true,
        persistence: "localStorage",
      });
      if (user) mixpanel?.identify(user.email || user.mobile);
    }
  }, [user]);
};

export const trackMixPanelEvent = (
  eventName: string,
  eventProperties?: any
) => {
  if (ENV === "PROD") {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_API_KEY as string, {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });
    mixpanel.track(eventName, eventProperties);
  }
};
