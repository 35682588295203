import * as amplitude from "@amplitude/analytics-browser";
import { useEffect } from "react";
import { useUserStore } from "store/useUserStore";

export enum AmplitudeEvents {
  LOGIN = "Login",
  LOGIN_PROVIDER = "Login Provider",
  LOGOUT = "Logout",
  SIGNUP = "Signup",
  SEARCH = "Search",
  BILLING_INFORMATION = "Billing information",
  VIEW_HOTEL = "Hotel Details",
  VIEW_PAYMENT_DETAILS = "Payment Confirmation",
  VIEW_PROFILE = "View Profile",
  EDIT_PROFILE = "Edit Profile",
  CHANGE_PASSWORD = "Change Password",
  FORGOT_PASSWORD = "Forgot Password",
  RESET_PASSWORD = "Reset Password",
  CANCEL_BOOKING = "Cancel Booking",
  PAYMENT_SUCCESS = "Payment Success",
  PAYMENT_FAILED = "Payment Failed",
  PAYMENT_METHOD_CREDIT_CARD = "Credit Card",
  PAYMENT_METHOD_TRAVEL_CREDITS = "Travel Credits",
  PAYMENT_METHOD_WALLET = "Wallet",
}

const environment = process.env.NEXT_PUBLIC_ENV;

export const useAmplitudeTracker = () => {
  if (environment === "DEV") return; // Don't track events in development
  const { user } = useUserStore();

  useEffect(() => {
    amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string, {
      defaultTracking: false,
    });
  }, []);

  useEffect(() => {
    if (user) amplitude.setUserId(user.email || user.mobile);
  }, [user]);
};

export const trackEvent = (eventName: string, eventProperties?: any) => {
  if (environment === "DEV") return;
  amplitude.track(eventName, eventProperties);
};
