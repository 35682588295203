import { AmplitudeEvents, trackEvent } from "hooks/useAmplitudeTracker";
import { trackMixPanelEvent } from "hooks/useMixPanelTracker";
import { apiServices } from "utils/configs/apiServices";
import { axiosInstance } from "utils/configs/axiosInstance";
import { logError } from "utils/helpers/loggers";
import { create, StoreApi, UseBoundStore } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type UserStoreState = {
  token: string | null;
  user: any | null;
  deviceToken: string | null;
  nationalityCode: string | null;
  favourite?: boolean;
  visitorId: string | null;
};

type UserStoreActions = {
  logout: () => void;
  loginWithProvider: (data: {
    firstName: string;
    provider: string;
    localId: string;
    lastName: string;
    photoUrl: string;
    email: string;
  }) => Promise<{ isAuth: boolean; error?: any }>;
  loginUser: (
    data: { email?: string; phone?: string; password: string },
    phoneCode: string
  ) => Promise<{ isAuth: boolean; error?: any }>;
  setNationalityCode: (nationalityCode: string) => void;
  setUser: (data: any) => void;
  setDeviceToken: (deviceToken: string) => void;
  setFavourite: (favouriteHotel: boolean) => void;
};

type UserStore = UserStoreState & UserStoreActions;

export const useUserStore = create<UserStore, [["zustand/persist", UserStore]]>(
  persist(
    (set, get) => ({
      token: null,
      user: null,
      deviceToken: null,
      nationalityCode: null,
      visitorId: null,
      logout: () => {
        set(() => ({ token: null, user: null }));
      },
      loginWithProvider: async ({
        firstName,
        provider,
        localId,
        lastName,
        photoUrl,
        email,
      }: any) => {
        const device_token = get().deviceToken;
        try {
          const response = await axiosInstance.post(
            apiServices.user.socialLogin,
            {
              provider,
              first_name: firstName,
              last_name: lastName,
              email,
              image: photoUrl,
              provider_token: localId,
              device_token,
              user_type: "social",
            }
          );
          trackEvent(AmplitudeEvents.LOGIN_PROVIDER, { email });
          trackMixPanelEvent(AmplitudeEvents.LOGIN_PROVIDER, { email });
          const token = response?.data.token;
          const user = response?.data.data;
          set(() => ({ token, user }));
          return { isAuth: true };
        } catch (error: any) {
          logError(error.response.data)
          return { isAuth: false, error: error.response.data };
        }
      },

      loginUser: async (data: any, phoneCode: string) => {
        try {
          let response;

          if (data.email) {
            response = await axiosInstance.post(apiServices.login, {
              email: data.email,
              password: data.password,
            });
          }
          if (data.phone) {
            const phoneNumber = "+" + phoneCode + data.phone;
            response = await axiosInstance.post(apiServices.login, {
              mobile: phoneNumber,
              password: data.password,
            });
          }
          const eventProperties = {
            email: data.email,
            phone: data.phone,
          };
          trackEvent(AmplitudeEvents.LOGIN, eventProperties);
          const token = response?.data.token;
          const user = response?.data.data;
          set(() => ({ token, user }));
          return { isAuth: true };
        } catch (error: any) {
          logError(error.response.data.message)
          return { isAuth: false, error: error.response.data.message };
        }
      },
      setNationalityCode: (nationalityCode: string) => {
        set(() => ({ nationalityCode }));
      },
      setVisitorId: (visitorId: string) => {
        set(() => ({  visitorId }));
      },
      setUser: (data: any) => {
        set(() => ({ user: data }));
      },
      setDeviceToken: (deviceToken: string) => {
        set(() => ({ deviceToken }));
      },
      setFavourite: (favouriteHotel: boolean) => {
        set(() => ({ favourite: favouriteHotel }));
      },
    }),
    {
      name: "UserStore",
    }
  )
);

export const registrationMethodStore: UseBoundStore<StoreApi<unknown>> = create(
  persist(
    (set) => ({
      registrationMethod: {
        data: "",
        method: "",
      },
      setRegistrationMethod: (data: any) => {
        set(() => ({ registrationMethod: data }));
      },
    }),
    {
      name: "registrationData",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
